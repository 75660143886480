import React, { FC } from "react";
import clsx from "clsx";
import {
  NumberInput as NumberInputMantine,
  NumberInputProps,
} from "@mantine/core";
import classes from "./Input.module.css";

const NumberInput: FC<NumberInputProps> = ({ classNames, ...props }) => {
  return (
    <NumberInputMantine
      {...props}
      classNames={{
        ...classNames,
        wrapper: props.variant === "black" ? classes.blackWrapper : "",
        input:
          props.variant === "black"
            ? clsx(classes.blackInput, (classNames as any)?.input)
            : classes.input,
      }}
      size={props?.size || "lg"}
    />
  );
};

export default NumberInput;
