import { matchPath, useLocation } from "react-router-dom";
import { Combobox, Flex, useCombobox } from "@mantine/core";
import { ReactComponent as SelectIcon } from "../../assets/icons/SelectIcon.svg";
import classes from "./Navbar.module.css";
import { NavItem } from "./types";

function MobileDropdown({
  links,
  navItems,
}: {
  links: React.ReactNode[];
  navItems: NavItem[];
}) {
  const location = useLocation();
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const value = navItems.find((item) => {
    const currentPage = matchPath(item.to, location.pathname);
    if (item.links?.length) {
      return (
        currentPage ||
        item.links.some((link) => matchPath(link.link, location.pathname))
      );
    }
    return currentPage;
  });

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      transitionProps={{ duration: 200, transition: "pop" }}
      onOptionSubmit={(val) => {
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <div
          onClick={() => combobox.toggleDropdown()}
          className={classes.dropdownMobileValue}
        >
          <Flex align="center">{value?.label}</Flex>
          <SelectIcon className={classes.linksIcon} />
        </div>
      </Combobox.Target>

      <Combobox.Dropdown className={classes.dropdownMobile}>
        <Combobox.Options onClick={() => combobox.closeDropdown()}>
          {links}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
export default MobileDropdown;
