import { ActionIcon, Box, Button, Flex, Text } from "@mantine/core";
import ListItem from "../../../components/ListItem";
import Modal from "./Modal";
import { useDisclosure } from "@mantine/hooks";
import { useQuery } from "@tanstack/react-query";
import classes from "./Courses.module.css";
import PageContainer from "../../../components/PageContainer";
import {
  Course,
  createCourse,
  getCourses,
  removeCourse,
  updateCourse,
} from "../../../api/courses";
import { Link } from "react-router-dom";
import { IconArrowRight, IconEdit, IconTrash } from "@tabler/icons-react";
import useUser from "../../../hooks/useUser";
import { UserRole } from "../../../api/users";
import { useState } from "react";
import { modals } from "@mantine/modals";

const Courses = () => {
  const { user } = useUser();

  const { data: courses, refetch } = useQuery({
    queryKey: ["courses"],
    queryFn: getCourses,
  });
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);

  const openRemoveModal = (course: Course) =>
    modals.openConfirmModal({
      title: "Підтвердіть дію",
      size: "sm",
      radius: "md",
      withCloseButton: false,
      children: (
        <Text size="sm">
          Чи впевнені що хочете видалити <b>{course.name}</b> курс? (всі його
          дані будуть видалені)
        </Text>
      ),
      labels: { confirm: "Підтвердити", cancel: "Відмінити" },
      onCancel: () => {
        setSelectedCourse(null);
      },
      onConfirm: async () => {
        await removeCourse(course._id);
        await refetch();
      },
    });

  const isAdmin = user.role === UserRole.ADMIN;

  return (
    <PageContainer title="Курси">
      {isAdmin && (
        <Flex pt="24px" pb="24px">
          <Button ml="auto" onClick={open}>
            Створити новий курс
          </Button>
        </Flex>
      )}
      <div>
        {courses?.map((course) => (
          <ListItem key={course._id} className={classes.listItem}>
            <Flex>
              <Box mr="20px">{course.name}</Box>
              <Box mr="20px">{course.price}</Box>
            </Flex>
            <Flex>
              {isAdmin && (
                <>
                  <ActionIcon
                    variant="filled"
                    aria-label="Edit"
                    mr="10px"
                    onClick={() => {
                      open();
                      setSelectedCourse(course);
                    }}
                  >
                    <IconEdit
                      style={{ width: "70%", height: "70%" }}
                      stroke={1.5}
                    />
                  </ActionIcon>
                  <ActionIcon
                    variant="filled"
                    aria-label="Remove"
                    onClick={() => {
                      openRemoveModal(course);
                    }}
                  >
                    <IconTrash
                      style={{ width: "70%", height: "70%" }}
                      stroke={1.5}
                    />
                  </ActionIcon>
                </>
              )}

              <ActionIcon
                ml="30px"
                variant="filled"
                aria-label="Go-to"
                component={Link}
                to={course._id}
              >
                <IconArrowRight
                  style={{ width: "70%", height: "70%" }}
                  stroke={1.5}
                />
              </ActionIcon>
            </Flex>
          </ListItem>
        ))}
      </div>
      {opened && (
        <Modal
          opened={opened}
          defaultValues={selectedCourse}
          onClose={() => {
            if (selectedCourse) {
              setSelectedCourse(null);
            }
            close();
          }}
          onSubmit={async (values) => {
            if (selectedCourse) {
              await updateCourse(selectedCourse._id, values);
            } else {
              await createCourse(values);
            }
            await refetch();
          }}
        />
      )}
    </PageContainer>
  );
};

export default Courses;
