import { ReqMethod, fetchHandler } from "..";

type SignInParams = {
  email: string;
  password: string;
};

export const logOut = async () => {
  await fetchHandler(
    `/auth/logout`,
    {
      method: ReqMethod.POST,
    },
    true,
  );
};
export const signIn = async (params: SignInParams) => {
  await fetchHandler(
    `/auth/sign-in`,
    {
      method: ReqMethod.POST,
      body: { ...params, method: "admin" },
    },
    true,
  );
};
