import { useQuery } from "@tanstack/react-query";
import classes from "./Lesson.module.css";
import PageContainer from "../../../components/PageContainer";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { COURSE_PATH, ROOT_PATH } from "../../routes";
import {
  Lesson as LessonType,
  LessonBlockType,
  getCourseLesson,
  LessonBlock,
  updateLessonBlock,
} from "../../../api/courses";
import { Flex } from "@mantine/core";
import { useState } from "react";
import clsx from "clsx";
import StudyMaterial from "./components/StudyMaterial";
import VideoMaterial from "./components/VideoMaterial";
import HomeWork from "./components/HomeWork";
import Test from "./components/Test";

const navLabels = [
  "Навчальний матеріал",
  "Відео-матеріал",
  "Тест",
  "Домашнє завдання",
];

const getLessonBlock = (
  lesson: LessonType,
  type: LessonBlockType,
): LessonBlock => {
  const lessonBlock = lesson.lessonBlocks.find((block) => block.type === type);
  return lessonBlock as LessonBlock;
};
const Lesson = () => {
  const [selectedNav, setSelectedNav] = useState<number>(0);
  const { courseId, blockId, lessonId } = useParams();
  const navigate = useNavigate();

  const {
    data: lesson,
    isFetched,
    refetch,
  } = useQuery({
    queryKey: ["lesson", lessonId],
    queryFn: async () =>
      courseId &&
      blockId &&
      lessonId &&
      getCourseLesson(courseId, blockId, lessonId),
  });

  if (!lesson) {
    if (isFetched) {
      const redirectPath = courseId
        ? generatePath(COURSE_PATH, { courseId })
        : ROOT_PATH;
      navigate(redirectPath);
    }
    return null;
  }

  const handleUpdateLessonBlock = async (lessonBlock: LessonBlock) => {
    if (courseId && blockId && lessonId) {
      await updateLessonBlock({
        courseId,
        blockId,
        lessonId,
        lessonBlockId: lessonBlock._id,
        content: lessonBlock.content,
      });
      await refetch();
    }
  };
  const contents = [
    <StudyMaterial
      lesson={lesson}
      lessonBlock={getLessonBlock(lesson, LessonBlockType.LearningMaterial)}
      onUpdate={handleUpdateLessonBlock}
    />,
    <VideoMaterial
      lesson={lesson}
      lessonBlock={getLessonBlock(lesson, LessonBlockType.Video)}
      onUpdate={handleUpdateLessonBlock}
    />,
    <Test
      lesson={lesson}
      lessonBlock={getLessonBlock(lesson, LessonBlockType.Test)}
      onUpdate={handleUpdateLessonBlock}
    />,
    <HomeWork
      lesson={lesson}
      lessonBlock={getLessonBlock(lesson, LessonBlockType.HomeWork)}
      onUpdate={handleUpdateLessonBlock}
    />,
  ];

  return (
    <PageContainer title={lesson.name}>
      <div className={classes.pageContent}>
        <Flex className={classes.lessonNav}>
          {navLabels.map((label, index) => (
            <div
              className={clsx(
                classes.navItem,
                index === selectedNav && classes.active,
              )}
              onClick={() => setSelectedNav(index)}
            >
              {label}
            </div>
          ))}
        </Flex>
        {contents[selectedNav]}
      </div>
    </PageContainer>
  );
};

export default Lesson;
