import { FC, useState } from "react";
import { hasLength, useForm } from "@mantine/form";
import { Button, Flex, Text } from "@mantine/core";
import classes from "../Course.module.css";
import Input from "../../../../components/Inputs/Input";
import { createCourseBlock } from "../../../../api/courses";

type FormValues = { name: string };

const CreateCourseBlockForm: FC<{
  courseId: string;
  refetchCourseBlocks: () => Promise<any>;
}> = ({ courseId, refetchCourseBlocks }) => {
  const form = useForm<FormValues>({
    mode: "controlled",
    initialValues: { name: "" },
    validate: {
      name: hasLength({ min: 2 }, "Must be at least 2 characters"),
    },
  });

  const [error, setError] = useState<string>("");
  const handleSubmit = async (values: FormValues) => {
    try {
      setError("");

      await createCourseBlock(courseId, values.name);
      await refetchCourseBlocks();
      form.reset();
    } catch (e: any) {
      console.log("e", e);
      setError(e?.message);
    }
  };

  return (
    <form
      onSubmit={form.onSubmit(handleSubmit)}
      className={classes.createCourseBlockForm}
    >
      <Flex className={classes.createCourseBlockFormContent}>
        <Input
          {...form.getInputProps("name")}
          placeholder="Введіть назву блока курсу"
          className={classes.blockNameInput}
          mr="sm"
        />

        <Button
          type="submit"
          size="lg"
          fz="md"
          display="block"
          className={classes.createBlockButton}
          disabled={form.getValues().name.length <= 2}
        >
          Створити блок
        </Button>
      </Flex>
      {error && (
        <Text mt="md" className={classes.errorField}>
          {error}
        </Text>
      )}
    </form>
  );
};

export default CreateCourseBlockForm;
