import { createTheme, MantineColorsTuple, rem } from "@mantine/core";

export const primaryColorHex = "#EA3359";
export const bodyTextColorHex = "#9C9CA4";

export const electricLimeHex = "#D4FF01";

const colors: Record<string, MantineColorsTuple> = {
  amaranth: [
    "#ffe9ef",
    "#ffd2dc",
    "#f8a4b6",
    "#f2728d",
    "#ec486a",
    primaryColorHex,
    "#e91d4a",
    "#d00e3b",
    "#ba0433",
    "#a3002a",
  ],
};
const theme = createTheme({
  /** Put your mantine theme override here */
  fontFamily: "Montserrat",
  fontFamilyMonospace: "Courier New",
  colors: {
    amaranth: colors.amaranth,
  },
  headings: {
    sizes: {
      h6: { fontSize: "12" },
    },
  },
  fontSizes: {
    xs: rem(10),
    sm: rem(11),
    md: rem(14),
    lg: rem(16),
    xl: rem(20),
  },
  primaryColor: "amaranth",
  radius: {
    sm: "6px",
  },
  defaultRadius: "sm",
});

export default theme;
