import React, { useState } from "react";
import { hasLength, isEmail, useForm } from "@mantine/form";
import { Button, Flex, Text } from "@mantine/core";
import classes from "./SignIn.module.css";
import Input from "../../../components/Inputs/Input";
import PasswordInput from "../../../components/Inputs/PasswordInput";
import { useNavigate } from "react-router-dom";
import { signIn } from "../../../api/auth";

type SignInFormValues = {
  email: string;
  password: string;
};

const Form = () => {
  const [error, setError] = useState<string>("");

  const navigate = useNavigate();
  const form = useForm<SignInFormValues>({
    mode: "controlled",
    initialValues: { password: "", email: "" },
    validate: {
      password: hasLength({ min: 3 }, "Must be at least 10 characters"),
      email: isEmail("Invalid email"),
    },
  });

  const handleSubmit = async (values: SignInFormValues) => {
    try {
      setError("");

      await signIn(values);
      navigate("/");
    } catch (e: any) {
      console.log("e", e);
      setError(e?.message);
    }
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Input
        {...form.getInputProps("email")}
        mt="xl"
        label="E-mail"
        miw="335px"
        placeholder="Введіть ваш E-mail"
      />

      <PasswordInput
        {...form.getInputProps("password")}
        mt="xl"
        type="password"
        label="Пароль"
        placeholder="Пароль"
        miw="335px"
      />

      {error && (
        <Text mt="md" className={classes.errorField} c="red">
          {error}
        </Text>
      )}
      <Flex mt="xl" justify="flex-end" align="center">
        <Button
          type="submit"
          className={classes.submitButton}
          size="lg"
          fz="md"
          display="block"
        >
          Вхід
        </Button>
      </Flex>
    </form>
  );
};

export default Form;
