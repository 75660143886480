import React, { FC } from "react";
import { Box, Title, Text } from "@mantine/core";
import classes from "./AnonymousLayout.module.css";
import { primaryColorHex } from "../../../theme";
import { Link } from "react-router-dom";

type AnonymousLayoutProps = {
  form: React.ReactNode;
  title: string;
  subtitle?: string;
  actionLink?: {
    title: string;
    to: string;
  };
};

const AnonymousLayout: FC<AnonymousLayoutProps> = ({
  form,
  title,
  subtitle,
  actionLink,
}) => {
  return (
    <div className={classes.page}>
      <div className={classes.formContainer}>
        <div>
          <Title order={1} fz={42} fw={400} mb={10}>
            {title}
          </Title>
          <Box display="flex" mb="30px">
            {subtitle && (
              <Text size="md" mr="12px">
                {subtitle}
              </Text>
            )}
            {actionLink && (
              <Link to={actionLink.to} style={{ color: primaryColorHex }}>
                {actionLink.title}
              </Link>
            )}
          </Box>
        </div>
        {form}
      </div>
    </div>
  );
};

export default AnonymousLayout;
