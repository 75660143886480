import { ActionIcon, Box, Button, Flex, Text } from "@mantine/core";
import ListItem from "../../../components/ListItem";
import Modal from "./Modal";
import { useDisclosure } from "@mantine/hooks";
import {
  User,
  UserRole,
  createUser,
  getUsers,
  removeUser,
  updateUser,
} from "../../../api/users";
import { useQuery } from "@tanstack/react-query";
import classes from "./RootPage.module.css";
import { useState } from "react";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import PageContainer from "../../../components/PageContainer";
import useUser from "../../../hooks/useUser";

const Users = () => {
  const { user } = useUser();

  const { data: users, refetch } = useQuery({
    queryKey: ["users"],
    queryFn: getUsers,
  });
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const openRemoveModal = (user: User) =>
    modals.openConfirmModal({
      title: "Підтвердіть дію",
      size: "sm",
      radius: "md",
      withCloseButton: false,
      children: (
        <Text size="sm">Чи впевнені що хочете видалити користувача?</Text>
      ),
      labels: { confirm: "Підтвердити", cancel: "Відмінити" },
      onCancel: () => {
        setSelectedUser(null);
      },
      onConfirm: async () => {
        await removeUser(user._id);
        await refetch();
      },
    });

  const isAdmin = user.role === UserRole.ADMIN;
  return (
    <PageContainer title="Користувачі">
      <Flex pt="24px" pb="24px">
        {isAdmin && (
          <Button ml="auto" onClick={open}>
            Створити нового користувача
          </Button>
        )}
      </Flex>
      <div>
        {users?.map((user) => (
          <ListItem key={user._id} className={classes.listItem}>
            <Flex className={classes.userData}>
              <Box mr="20px">
                {user.firstName} {user.lastName}
              </Box>
              <Box mr="20px">{user.email}</Box>
              <Box mr="20px">{user.role}</Box>
            </Flex>
            {isAdmin && (
              <Flex>
                <ActionIcon
                  variant="filled"
                  aria-label="Edit"
                  mr="10px"
                  onClick={() => {
                    open();
                    setSelectedUser(user);
                  }}
                >
                  <IconEdit
                    style={{ width: "70%", height: "70%" }}
                    stroke={1.5}
                  />
                </ActionIcon>
                <ActionIcon
                  variant="filled"
                  aria-label="Remove"
                  onClick={() => {
                    openRemoveModal(user);
                  }}
                >
                  <IconTrash
                    style={{ width: "70%", height: "70%" }}
                    stroke={1.5}
                  />
                </ActionIcon>
              </Flex>
            )}
          </ListItem>
        ))}
      </div>
      {opened && (
        <Modal
          opened={opened}
          defaultValues={selectedUser}
          onClose={() => {
            close();
            setSelectedUser(null);
          }}
          onSubmit={async (values) => {
            if (selectedUser) {
              await updateUser({ _id: selectedUser._id, ...values });
            } else {
              await createUser(values);
            }
            await refetch();
          }}
        />
      )}
    </PageContainer>
  );
};

export default Users;
