import { RichTextEditor, Link } from "@mantine/tiptap";
import { Editor, useEditor } from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
import Image from "@tiptap/extension-image";
import { FC, useState } from "react";
import { uploadPublicFile } from "../../api";
import { IconPhotoPlus } from "@tabler/icons-react";
import { ActionIcon, Text } from "@mantine/core";

type Props = {
  content: string;
  onUpdate: (html: string) => void;
};

const FIVE_MB = 5242880;

const RichText: FC<Props> = ({ content, onUpdate }) => {
  const [fileError, setFileError] = useState<string>("");

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      Image,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
    ],
    content,
    onUpdate: ({ editor }) => onUpdate(editor.getHTML()),
  });

  const addImage = async (event: any) => {
    setFileError("");
    const file = event?.target?.files[0];
    if (!file) {
      console.error("File isn't uploaded");
      return;
    }
    if (file?.size > FIVE_MB) {
      setFileError("Розмір файлу має бути меньше 5мб");
      return;
    }
    let formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);
    const { fileUrl } = await uploadPublicFile(formData);
    if (fileUrl && editor) {
      editor.chain().focus().setImage({ src: fileUrl }).run();
    }
  };
  return (
    <>
      {fileError && (
        <Text mb="md" c="red">
          {fileError}
        </Text>
      )}
      <RichTextEditor editor={editor}>
        <RichTextEditor.Toolbar c="black">
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.ClearFormatting />
            <RichTextEditor.Highlight />
            <RichTextEditor.Code />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.H1 />
            <RichTextEditor.H2 />
            <RichTextEditor.H3 />
            <RichTextEditor.H4 />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Blockquote />
            <RichTextEditor.Hr />
            <RichTextEditor.BulletList />
            <RichTextEditor.OrderedList />
            <RichTextEditor.Subscript />
            <RichTextEditor.Superscript />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Link />
            <RichTextEditor.Unlink />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.AlignLeft />
            <RichTextEditor.AlignCenter />
            <RichTextEditor.AlignJustify />
            <RichTextEditor.AlignRight />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <input
              type="file"
              onChange={addImage}
              accept="image/*"
              name="secondaryLogo"
              style={{ display: "none" }}
              id="secondary-logo-upload"
              multiple={false}
            />
            <ActionIcon
              variant="default"
              aria-label="Go-to"
              size={26}
              component="label"
              htmlFor="secondary-logo-upload"
            >
              <IconPhotoPlus style={{ width: 16, height: 16 }} stroke={1} />
            </ActionIcon>
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Undo />
            <RichTextEditor.Redo />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>

        <RichTextEditor.Content />
      </RichTextEditor>
    </>
  );
};
export default RichText;
