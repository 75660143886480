import classes from "./RichTextContent.module.css";

function RichTextContent({ content }: { content: string }) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: content }}
      className={classes.richTextContent}
    />
  );
}
export default RichTextContent;
