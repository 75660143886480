import {
  Button,
  FileButton,
  Image,
  Modal,
  NumberInput,
  Text,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
import Input from "../../../components/Inputs/Input";
import classes from "./Courses.module.css";
import { Course } from "../../../api/courses";

type CreateUserModal = {
  opened: boolean;
  onClose: () => void;
  onSubmit: (values: FormData) => Promise<void>;
  defaultValues?: Course | null;
};
export type CreateUserFormValues = {
  name: string;
  description: string;
  features: string[];
  price: number;
};

const FIVE_MB = 5242880;

const RootPage: React.FC<CreateUserModal> = ({
  opened,
  onClose,
  onSubmit,
  defaultValues,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [url, setUrl] = useState<string>("");

  const [error, setError] = useState<string>("");
  const [fileError, setFileError] = useState<string>("");

  const form = useForm<CreateUserFormValues>({
    mode: "controlled",
    initialValues: defaultValues
      ? {
          name: defaultValues.name,
          description: defaultValues.description,
          features: defaultValues.features,
          price: defaultValues.price,
        }
      : {
          name: "",
          description: "",
          features: [],
          price: 0,
        },
  });

  const handleSubmit = async (values: CreateUserFormValues) => {
    try {
      setError("");

      const formData = new FormData();
      const submitValues = {
        ...values,
        features: JSON.stringify(values.features),
      };
      for (const key in submitValues) {
        formData.append(key, (submitValues as any)[key]);
      }
      if (file) {
        formData.append("file", file);
      }
      await onSubmit(formData);
      onClose();
    } catch (e: any) {
      console.log("e", e);
      setError(e?.message);
    }
  };

  const isEdit = !!defaultValues;
  const title = isEdit ? "Змінити курс" : "Cтворити новий курс";
  const submitLabel = isEdit ? "Змінити" : "Створити";
  const img = url || defaultValues?.image;
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      bg="#000000"
      overlayProps={{ className: classes.modalOverlay }}
      title={title}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        {img && <Image radius="md" h={140} src={img} />}

        <FileButton
          onChange={(uploadedFile) => {
            setFileError("");
            if (!uploadedFile) {
              return;
            }
            if (uploadedFile?.size > FIVE_MB) {
              setFileError("Розмір файлу має бути меньше 5мб");
              return;
            }
            setFile(uploadedFile);

            const fileUrl = URL.createObjectURL(uploadedFile);
            setUrl(fileUrl);
          }}
          accept="image/*"
        >
          {(props) => (
            <Button {...props} mt="xs">
              Додати/Змінити Лого курса
            </Button>
          )}
        </FileButton>
        {fileError && (
          <Text mt="md" c="red">
            {fileError}
          </Text>
        )}

        <Input
          mt="md"
          required
          {...form.getInputProps("name")}
          label="Назва"
          placeholder="Введіть Назву Курса"
        />
        <Textarea
          required
          minRows={4}
          resize="vertical"
          {...form.getInputProps("description")}
          label="Опис"
          placeholder="Введіть Опис Курсу"
          mt="xs"
        />

        <Input
          required
          {...form.getInputProps("features.0")}
          label="Особливість курса 1"
          placeholder="Введіть Особливість Курса"
          mt="xs"
        />
        <Input
          required
          {...form.getInputProps("features.1")}
          label="Особливість курса 2"
          placeholder="Введіть Особливість Курса"
          mt="xs"
        />
        <Input
          required
          {...form.getInputProps("features.2")}
          label="Особливість курса 3"
          placeholder="Введіть Особливість Курса"
          mt="xs"
        />
        <Input
          required
          {...form.getInputProps("features.3")}
          label="Особливість курса 4"
          placeholder="Введіть Особливість Курса"
          mt="xs"
        />
        <NumberInput
          required
          {...form.getInputProps("price")}
          mt="xs"
          label="Ціна"
          placeholder="Вкажіть Ціну Курса"
        />

        {error && (
          <Text mt="md" className={classes.errorField} c="red">
            {error}
          </Text>
        )}
        <Button
          type="submit"
          mt="md"
          className={classes.submitButton}
          size="lg"
          fz="md"
          ml="auto"
          display="block"
        >
          {submitLabel}
        </Button>
      </form>
    </Modal>
  );
};

export default RootPage;
