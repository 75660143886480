import { ActionIcon, Box, Button, Flex, Text, Title } from "@mantine/core";
import {
  Course,
  getUserHomeworks,
  HomeWork,
  Lesson,
} from "../../../../../api/courses";
import { FC, useState } from "react";
import ListItem from "../../../../../components/ListItem";
import { useQuery } from "@tanstack/react-query";
import { IconCircleArrowUpRightFilled } from "@tabler/icons-react";
import HomeworkConversationModal from "./HomeworkConversationModal";

type Props = {
  lesson: Lesson;
};
export type UserWithHomeWorks = { [key: string]: HomeWork[] };

function groupBy<T>(
  list: T[],
  keyGetter: (i: T) => void,
): { [key: string]: T[] } {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  const object = Object.fromEntries(map.entries());
  return object;
}

const HomeWorksList: FC<Props> = ({ lesson }) => {
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const { data: homeWorks, refetch } = useQuery({
    queryKey: ["homeWorks", lesson._id],
    queryFn: async () => getUserHomeworks(lesson._id),
  });

  if (!homeWorks?.length) {
    return null;
  }
  const homeworksByUser = groupBy<HomeWork>(
    homeWorks,
    (homework) => homework.user._id,
  );

  return (
    <>
      {Object.entries(homeworksByUser).map(([userId, homeWorks]) => {
        if (!homeWorks.length) {
          return null;
        }
        const homeWork = homeWorks[0];
        return (
          <ListItem key={userId} variant="contained">
            <Flex justify="space-between" align="center">
              <Flex w="100%" wrap="nowrap">
                <Flex w="33%">
                  Студент: {homeWork.user.firstName} {homeWork.user.lastName}
                </Flex>
              </Flex>
              <ActionIcon
                variant="filled"
                aria-label="Remove"
                onClick={() => setSelectedUserId(userId)}
              >
                <IconCircleArrowUpRightFilled
                  style={{ width: "70%", height: "70%" }}
                  stroke={1.5}
                />
              </ActionIcon>
            </Flex>
          </ListItem>
        );
      })}
      {selectedUserId && (
        <HomeworkConversationModal
          opened
          userId={selectedUserId}
          homeworks={homeworksByUser[selectedUserId]}
          onClose={() => {
            setSelectedUserId(null);
          }}
          refetchHomeworks={async () => {
            await refetch();
          }}
        />
      )}
    </>
  );
};

export default HomeWorksList;
