import { ActionIcon, Box, Button, Flex, Text, Title } from "@mantine/core";
import classes from "../../StudyFlow.module.css";
import { Course, Lesson, LessonBlockType } from "../../../../../api/courses";
import { FC } from "react";
import HomeWorksList from "./HomeWorksList";
import { electricLimeHex } from "../../../../../theme";

type Props = {
  course: Course;
};

const Lessons: FC<Props> = ({ course }) => {
  const lessons = course?.courseBlocks?.length
    ? course?.courseBlocks.reduce((acc, cur) => {
        const blockLessons = cur.courseLessons.length ? cur.courseLessons : [];
        return [...acc, ...blockLessons];
      }, [] as Lesson[])
    : [];
  return (
    <div className={classes.pageContent}>
      <Flex justify="space-between" mb="30px">
        <Flex align="center">
          <Title order={3} mr="20px">
            Домашні завдання
          </Title>
        </Flex>
      </Flex>
      <div>
        {!!lessons.length
          ? lessons
              .filter((lesson) =>
                lesson.lessonBlocks.some(
                  (lessonBlock) =>
                    lessonBlock.type === LessonBlockType.HomeWork &&
                    lessonBlock.content,
                ),
              )
              .map((lesson) => (
                <Box key={lesson._id} p={8}>
                  <Flex mb={16}>
                    <Text c={electricLimeHex} fz="16px" mr="4px">
                      Назва уроку:
                    </Text>
                    <Text fw={500} fz="16px">
                      {lesson.name}
                    </Text>
                  </Flex>

                  <HomeWorksList lesson={lesson} />
                </Box>
              ))
          : "Немає уроків на потоці"}
      </div>
    </div>
  );
};

export default Lessons;
