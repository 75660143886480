import { Button, List, Modal, Text } from "@mantine/core";
import { useMemo, useState } from "react";
import classes from "../StudyFlow.module.css";
import { useQuery } from "@tanstack/react-query";
import { modals } from "@mantine/modals";
import { User, UserRole, getUsers } from "../../../../api/users";
import UsersTable from "./UsersTable";

type CreateUserModal = {
  opened: boolean;
  onClose: () => void;
  onSubmit: (selectedUsers: User[]) => Promise<void>;
  studyFlowUsers?: User[];
  role: UserRole;
};
export type CreateUserFormValues = {
  userIds: string[];
};

const AddUsersModal: React.FC<CreateUserModal> = ({
  opened,
  onClose,
  onSubmit,
  role,
  studyFlowUsers,
}) => {
  const { data: allUsers } = useQuery({
    queryKey: ["users"],
    queryFn: getUsers,
  });
  const [error, setError] = useState<string>("");
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

  const users = useMemo(
    () =>
      allUsers?.filter((user) => {
        const isUserAlreadyAdded = studyFlowUsers?.find(
          (studyFlowUser) => studyFlowUser._id === user._id,
        );
        return !isUserAlreadyAdded && user.role === role;
      }) || [],
    [studyFlowUsers, allUsers],
  );

  const openRemoveUserModal = (selectedUsers: User[]) =>
    modals.openConfirmModal({
      title: "Підтвердіть дію",
      size: "sm",
      radius: "md",
      withCloseButton: false,
      children: (
        <>
          <Text size="sm">
            Чи впевнені що хочете додати даних{" "}
            {role === UserRole.MENTOR ? "менторів" : "студентів"} до потоку?
          </Text>
          <List listStyleType="disc" mt="15px" size="sm">
            {selectedUsers.map((user) => (
              <List.Item>
                {user.firstName} {user.lastName}
              </List.Item>
            ))}
          </List>
        </>
      ),
      labels: { confirm: "Підтвердити", cancel: "Відмінити" },
      onCancel: () => {},
      onConfirm: async () => {
        try {
          await onSubmit(selectedUsers);
        } catch (e: any) {
          setError(e.message);
        }
        onClose();
      },
    });

  const handleSubmit = async () => {
    openRemoveUserModal(selectedUsers);
  };
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      classNames={{
        root: classes.modal,
      }}
      bg="#000000"
      size="600px"
      overlayProps={{ className: classes.modalOverlay }}
      title={`Виберіть ${role === UserRole.MENTOR ? "role" : "студентів"}`}
    >
      <UsersTable
        users={users}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
      />
      {error && (
        <Text mt="md" className={classes.errorField}>
          {error}
        </Text>
      )}
      <Button
        mt="md"
        disabled={!selectedUsers.length}
        className={classes.submitButton}
        size="sm"
        fz="md"
        ml="auto"
        display="block"
        onClick={handleSubmit}
      >
        Додати
      </Button>
    </Modal>
  );
};

export default AddUsersModal;
