import { Button, Flex, Title } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import classes from "./Course.module.css";
import PageContainer from "../../../components/PageContainer";
import { useNavigate, useParams } from "react-router-dom";
import { STUDY_FLOWS_PATH } from "../../routes";
import { getCourse, getCourseResources } from "../../../api/courses";
import CreateCourseResourceForm from "./components/CreateCourseResourceForm";
import { bodyTextColorHex } from "../../../theme";
import ResourceItem from "./components/ResourceItem";

const Resources = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();

  const { data: course } = useQuery({
    queryKey: ["course"],
    queryFn: async () => courseId && getCourse(courseId),
  });
  const { data: resources, refetch: refetchResources } = useQuery({
    queryKey: ["resources", courseId],
    queryFn: async () => (courseId ? getCourseResources(courseId) : undefined),
  });

  if (!course || !courseId) {
    navigate(STUDY_FLOWS_PATH);
    return null;
  }

  return (
    <PageContainer title={`Ресурси ${course?.name}`}>
      <Flex pt="24px" pb="24px" className={classes.header}>
        <CreateCourseResourceForm
          courseId={course._id}
          refetchResources={refetchResources}
        />
      </Flex>
      {resources?.length ? (
        resources.map((resource) => (
          <ResourceItem
            key={resource._id}
            resource={resource}
            courseId={course._id}
            refetchResources={refetchResources}
          />
        ))
      ) : (
        <Title
          order={4}
          fz={24}
          fw="normal"
          c={bodyTextColorHex}
          mb={48}
          mt={70}
          ta="center"
        >
          Цей курс не має ресурсів
        </Title>
      )}
    </PageContainer>
  );
};

export default Resources;
