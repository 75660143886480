import { Outlet, useNavigate } from "react-router-dom";
import { Flex } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import Navbar from "../../Navbar";
import { getMe, User } from "../../../api/users";
import classes from "./PrivateLayout.module.css";

export type PrivateOutletContext = { user: User };

function PrivateLayout() {
  const navigate = useNavigate();
  const {
    data: user,
    isFetched,
    isLoading,
  } = useQuery({
    queryKey: ["me"],
    queryFn: () => getMe(),
  });

  if (!user) {
    if (isLoading) {
      return null;
    }
    return null;
  }
  return (
    <Flex p="20px" className={classes.layout}>
      <Navbar />
      <Outlet context={{ user } as PrivateOutletContext} />
    </Flex>
  );
}

export default PrivateLayout;
