import { Button, Modal, Radio, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
import classes from "../StudyFlow.module.css";
import { useQuery } from "@tanstack/react-query";
import { getCourses } from "../../../../api/courses";

type CreateUserModal = {
  opened: boolean;
  onClose: () => void;
  onSubmit: (values: CreateUserFormValues) => Promise<void>;
  defaultValue?: string;
};
export type CreateUserFormValues = {
  courseId: string;
};

const RootPage: React.FC<CreateUserModal> = ({
  opened,
  onClose,
  onSubmit,
  defaultValue,
}) => {
  const { data: courses } = useQuery({
    queryKey: ["courses"],
    queryFn: getCourses,
  });
  const [error, setError] = useState<string>("");
  const form = useForm<CreateUserFormValues>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      courseId: defaultValue ? defaultValue : "",
    },
  });

  const handleSubmit = async (values: CreateUserFormValues) => {
    try {
      setError("");

      await onSubmit(values);
      onClose();
    } catch (e: any) {
      console.log("e", e);
      setError(e?.message);
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      bg="#000000"
      overlayProps={{ className: classes.modalOverlay }}
      title="Виберіть курс"
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Radio.Group {...form.getInputProps("courseId")} required>
          {courses?.map((course) => (
            <Radio value={course._id} label={course.name} size="md" mb="10px" />
          ))}
        </Radio.Group>

        {error && (
          <Text mt="md" className={classes.errorField}>
            {error}
          </Text>
        )}
        <Button
          type="submit"
          mt="md"
          disabled={!form.values.courseId}
          className={classes.submitButton}
          size="sm"
          fz="md"
          ml="auto"
          display="block"
        >
          Додати
        </Button>
      </form>
    </Modal>
  );
};

export default RootPage;
