import { ReqMethod, fetchHandler } from "..";
import { CreateUserFormValues } from "../../pages/Private/Users/Modal";

export enum UserRole {
  ADMIN = "ADMIN",
  USER = "USER",
  MENTOR = "MENTOR",
}

export type User = {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  telegramName: string;
  discordName: string;
  phoneNumber: string;
  role: UserRole;
};
export const getUsers = async (): Promise<User[]> =>
  fetchHandler(`/users`, {
    method: ReqMethod.GET,
  });
export const removeUser = async (id: string): Promise<User[]> =>
  fetchHandler(`/users/${id}`, {
    method: ReqMethod.DELETE,
  });

export const createUser = async (values: CreateUserFormValues) => {
  await fetchHandler(`/users`, {
    method: ReqMethod.POST,
    body: values,
  });
};
export const updateUser = async ({ _id, ...values }: User) => {
  await fetchHandler(`/users/${_id}`, {
    method: ReqMethod.PUT,
    body: values,
  });
};

export const getMe = async (): Promise<User> =>
  await fetchHandler(`/users/me`, {
    method: ReqMethod.GET,
  });
