import { ActionIcon, Box, Button, Flex, Text } from "@mantine/core";
import ListItem from "../../../components/ListItem";
import Modal from "./Modal";
import { useDisclosure } from "@mantine/hooks";
import { useQuery } from "@tanstack/react-query";
import classes from "./Courses.module.css";
import PageContainer from "../../../components/PageContainer";
import {
  createStudyFlow,
  getStudyFlows,
  removeStudyFlow,
  StudyFlow,
  updateStudyFlow,
} from "../../../api/studyFlows";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { modals } from "@mantine/modals";
import { IconArrowRight, IconEdit, IconTrash } from "@tabler/icons-react";
import { useState } from "react";

const StudyFlows = () => {
  const [selectedStudyFlow, setSelectedStudyFlow] = useState<StudyFlow | null>(
    null,
  );

  const { data: studyFlows, refetch } = useQuery({
    queryKey: ["studyFlows"],
    queryFn: getStudyFlows,
  });
  const [opened, { open, close }] = useDisclosure(false);

  const openRemoveModal = (studyFlowId: string) =>
    modals.openConfirmModal({
      title: "Підтвердіть дію",
      size: "sm",
      radius: "md",
      withCloseButton: false,
      children: (
        <Text size="sm">
          Чи впевнені що хочете видалити потів і всі його дані?
        </Text>
      ),
      labels: { confirm: "Підтвердити", cancel: "Відмінити" },
      onCancel: () => {},
      onConfirm: async () => {
        await removeStudyFlow(studyFlowId);
        await refetch();
      },
    });
  return (
    <PageContainer title="Потоки">
      <Flex pt="24px" pb="24px">
        <Button ml="auto" onClick={open}>
          Створити новий потік
        </Button>
      </Flex>
      <div>
        {studyFlows?.map((studyFlow) => (
          <ListItem key={studyFlow._id} className={classes.listItem}>
            <Flex className={classes.fieldData}>
              <Box mr="20px">{studyFlow.name}</Box>
              <Box mr="20px">
                Початок: {dayjs(studyFlow.startDate).format("YYYY-MM-DD")}
              </Box>
              <Box mr="20px">
                Кінець: {dayjs(studyFlow.endDate).format("YYYY-MM-DD")}
              </Box>
            </Flex>
            <Flex>
              <ActionIcon
                variant="filled"
                aria-label="Edit"
                mr="10px"
                onClick={() => {
                  open();
                  setSelectedStudyFlow(studyFlow);
                }}
              >
                <IconEdit
                  style={{ width: "70%", height: "70%" }}
                  stroke={1.5}
                />
              </ActionIcon>
              <ActionIcon
                variant="filled"
                aria-label="Remove"
                onClick={() => {
                  openRemoveModal(studyFlow._id);
                }}
              >
                <IconTrash
                  style={{ width: "70%", height: "70%" }}
                  stroke={1.5}
                />
              </ActionIcon>
              <ActionIcon
                ml="30px"
                variant="filled"
                aria-label="Go-to"
                component={Link}
                to={studyFlow._id}
              >
                <IconArrowRight
                  style={{ width: "70%", height: "70%" }}
                  stroke={1.5}
                />
              </ActionIcon>
            </Flex>
          </ListItem>
        ))}
      </div>
      {opened && (
        <Modal
          opened={opened}
          defaultValues={selectedStudyFlow}
          onClose={() => {
            if (selectedStudyFlow) {
              setSelectedStudyFlow(null);
            }
            close();
          }}
          onSubmit={async (values) => {
            if (selectedStudyFlow) {
              await updateStudyFlow(selectedStudyFlow._id, values);
            } else {
              await createStudyFlow(values);
            }
            await refetch();
          }}
        />
      )}
    </PageContainer>
  );
};

export default StudyFlows;
