import classes from "../Lesson.module.css";
import { Button, Title } from "@mantine/core";
import { FC, useState } from "react";
import { Lesson, LessonBlock } from "../../../../api/courses";
import RichText from "../../../../components/RichText";

type Props = {
  lesson: Lesson;
  lessonBlock: LessonBlock;
  onUpdate: (lessonBlock: LessonBlock) => Promise<void>;
};
const emptyDb = "";

const emptyHtml = "<p></p>";

const StudyMaterial: FC<Props> = ({ lesson, lessonBlock, onUpdate }) => {
  const [html, setHTML] = useState<string>(
    lessonBlock?.content === emptyDb ? emptyHtml : lessonBlock?.content,
  );

  const handleUpdate = async () => {
    await onUpdate({ ...lessonBlock, content: html });
  };
  return (
    <div className={classes.lessonContent}>
      <Title order={4} mb="24px" fz="24px" fw="700">
        {lesson.name}
      </Title>
      <RichText content={html} onUpdate={setHTML} />

      <Button
        mt="lg"
        ml="auto"
        onClick={handleUpdate}
        size="md"
        fz="sm"
        display="block"
        disabled={html === emptyHtml || html === lessonBlock?.content}
      >
        Оновити матеріал
      </Button>
    </div>
  );
};

export default StudyMaterial;
