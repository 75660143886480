import { Button, Modal, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
import classes from "../StudyFlow.module.css";
import { DateTimePicker } from "@mantine/dates";
import dayjs from "dayjs";
import Input from "../../../../components/Inputs/Input";
import { Event } from "../../../../api/studyFlows";

type CreateUserModal = {
  opened: boolean;
  onClose: () => void;
  onSubmit: (values: CreateEventSubmitValues) => Promise<void>;
  defaultValues?: Event | null;
};
export type CreateUserFormValues = {
  name: string;
  link: string;
  topic: string;
  startAt: Date;
};
export type CreateEventSubmitValues = {
  name: string;
  link: string;
  topic: string;
  startAt: string;
};

const AddEventModal: React.FC<CreateUserModal> = ({
  opened,
  onClose,
  onSubmit,
  defaultValues,
}) => {
  const [error, setError] = useState<string>("");
  const form = useForm<CreateUserFormValues>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: defaultValues
      ? {
          name: defaultValues.name,
          startAt: new Date(defaultValues.startAt),
          link: defaultValues.link,
          topic: defaultValues.topic,
        }
      : {
          name: "",
          startAt: new Date(),
          link: "",
          topic: "",
        },
    validate: {
      startAt: (value) => {
        return value.getTime() > new Date().getTime()
          ? false
          : "End date should be bigger than current time";
      },
    },
  });

  const handleSubmit = async (values: CreateUserFormValues) => {
    try {
      setError("");

      const submitValues = {
        ...values,
        startAt: dayjs(values.startAt).utc().format("YYYY-MM-DD, hh:mm"),
      };
      await onSubmit(submitValues);
      onClose();
    } catch (e: any) {
      console.log("e", e);
      setError(e?.message);
    }
  };
  const isEdit = !!defaultValues;
  const title = isEdit ? "Змінити подію" : "Створити нову подію";
  const submitLabel = isEdit ? "Змінити" : "Створити";

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      bg="#000000"
      overlayProps={{ className: classes.modalOverlay }}
      title={title}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Input
          required
          {...form.getInputProps("name")}
          label="Назва"
          placeholder="Введіть Назву Події"
        />
        <Input
          mt="md"
          required
          {...form.getInputProps("topic")}
          label="Опис"
          placeholder="Введіть Опис Події"
        />
        <Input
          mt="md"
          required
          {...form.getInputProps("link")}
          label="Лінк"
          placeholder="Введіть Лінк до Події"
        />
        <DateTimePicker
          mt="md"
          {...form.getInputProps("startAt")}
          label="Дата Події"
          placeholder="Виберіть Дату Початку Події"
          minDate={new Date()}
          classNames={{ levelsGroup: classes.levelsGroup }}
        />
        {error && (
          <Text mt="md" className={classes.errorField}>
            {error}
          </Text>
        )}
        <Button
          type="submit"
          mt="md"
          className={classes.submitButton}
          size="lg"
          fz="md"
          ml="auto"
          display="block"
        >
          {submitLabel}
        </Button>
      </form>
    </Modal>
  );
};

export default AddEventModal;
