import classes from "../Lesson.module.css";
import { Button, Title } from "@mantine/core";
import { FC } from "react";
import { Lesson, LessonBlock } from "../../../../api/courses";
import Input from "../../../../components/Inputs/Input";
import { useForm } from "@mantine/form";

type Props = {
  lesson: Lesson;
  lessonBlock: LessonBlock;
  onUpdate: (lessonBlock: LessonBlock) => Promise<void>;
};

type FormValues = { link: string };

export const websiteUrlValidationPattern =
  /^(ftp|http[s]?):\/\/[^\s/$.?#].[^\s]*($|\s|%20)/;

const Test: FC<Props> = ({ lesson, lessonBlock, onUpdate }) => {
  const form = useForm<FormValues>({
    mode: "controlled",
    initialValues: {
      link: lessonBlock?.content,
    },
    validate: {
      link: (value) =>
        !value.match(websiteUrlValidationPattern)
          ? "Введено некоректний лінк"
          : null,
    },
  });

  const handleSubmit = async (values: FormValues) => {
    await onUpdate({ ...lessonBlock, content: values.link });
  };
  return (
    <div className={classes.lessonContent}>
      <Title order={4} mb="24px" fz="24px" fw="700">
        {lesson.name}
      </Title>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Input
          {...form.getInputProps("link")}
          placeholder="Введіть відео лінк урока"
          size="lg"
        />
        <Button
          mt="lg"
          ml="auto"
          type="submit"
          size="md"
          fz="sm"
          display="block"
          disabled={
            !form.getValues().link.length ||
            form.getValues().link === lessonBlock.content
          }
        >
          Оновити тест лінк
        </Button>
      </form>
    </div>
  );
};

export default Test;
