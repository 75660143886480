import { Button, Modal, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
import Input from "../../../components/Inputs/Input";
import classes from "./Courses.module.css";
import { DatePickerInput } from "@mantine/dates";
import { StudyFlow } from "../../../api/studyFlows";
import dayjs from "dayjs";

type CreateUserModal = {
  opened: boolean;
  onClose: () => void;
  onSubmit: (values: CreateUserFormSubmitValues) => Promise<void>;
  defaultValues?: StudyFlow | null;
};
export type CreateUserFormValues = {
  name: string;
  startDate: Date;
  endDate: Date;
};
export type CreateUserFormSubmitValues = {
  name: string;
  startDate: string;
  endDate: string;
};

const RootPage: React.FC<CreateUserModal> = ({
  opened,
  onClose,
  onSubmit,
  defaultValues,
}) => {
  const [error, setError] = useState<string>("");
  const form = useForm<CreateUserFormValues>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: defaultValues
      ? {
          name: defaultValues.name,
          startDate: new Date(defaultValues.startDate),
          endDate: new Date(defaultValues.endDate),
        }
      : {
          name: "",
          startDate: new Date(),
          endDate: new Date(),
        },
    validate: {
      endDate: (value, values) => {
        return value.getTime() > values.startDate.getTime()
          ? false
          : "End date should be bigger than Start date";
      },
    },
  });

  const handleSubmit = async (values: CreateUserFormValues) => {
    try {
      setError("");

      const submitValues = {
        name: values.name,
        startDate: dayjs(values.startDate).utc().format("YYYY-MM-DD"),
        endDate: dayjs(values.endDate).utc().format("YYYY-MM-DD"),
      };
      await onSubmit(submitValues);
      onClose();
    } catch (e: any) {
      console.log("e", e);
      setError(e?.message);
    }
  };
  const isEdit = !!defaultValues;
  const title = isEdit ? "Змінити новий потік" : "Створити новий потік";
  const submitLabel = isEdit ? "Змінити" : "Створити";

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      bg="#000000"
      overlayProps={{ className: classes.modalOverlay }}
      title={title}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Input
          required
          {...form.getInputProps("name")}
          label="Назва"
          placeholder="Введіть Назву Потоку"
        />
        <DatePickerInput
          {...form.getInputProps("startDate")}
          label="Дата Початку Потоку"
          placeholder="Виберіть Дату Початку Потоку"
          minDate={new Date()}
          classNames={{ levelsGroup: classes.levelsGroup }}
        />
        <DatePickerInput
          {...form.getInputProps("endDate")}
          label="Дата Кінця Потоку"
          placeholder="Виберіть Дату Кінця Потоку"
          minDate={new Date()}
          classNames={{ levelsGroup: classes.levelsGroup }}
        />

        {error && (
          <Text mt="md" className={classes.errorField}>
            {error}
          </Text>
        )}
        <Button
          type="submit"
          mt="md"
          className={classes.submitButton}
          size="lg"
          fz="md"
          ml="auto"
          display="block"
        >
          {submitLabel}
        </Button>
      </form>
    </Modal>
  );
};

export default RootPage;
