import { FC, useState } from "react";
import { hasLength, useForm } from "@mantine/form";
import { Button, Flex, Text } from "@mantine/core";
import classes from "../Course.module.css";
import Input from "../../../../components/Inputs/Input";
import {
  LessonBlockType,
  createCourseLesson,
  createLessonBlock,
} from "../../../../api/courses";

type FormValues = { name: string };

type Props = {
  courseId: string;
  blockId: string;
  refetchCourseBlocks: () => Promise<any>;
};

const lessonBlocksToCreate = [
  {
    type: LessonBlockType.LearningMaterial,
    name: "Learning Material",
  },
  {
    type: LessonBlockType.Video,
    name: "Video",
  },
  {
    type: LessonBlockType.Test,
    name: "Test",
  },
  {
    type: LessonBlockType.HomeWork,
    name: "Home Work",
  },
];

const CreateLessonForm: FC<Props> = ({
  courseId,
  blockId,
  refetchCourseBlocks,
}) => {
  const form = useForm<FormValues>({
    mode: "controlled",
    initialValues: { name: "" },
    validate: {
      name: hasLength({ min: 2 }, "Must be at least 2 characters"),
    },
  });

  const [error, setError] = useState<string>("");
  const handleSubmit = async (values: FormValues) => {
    try {
      setError("");

      const lesson = await createCourseLesson(courseId, blockId, values.name);
      await Promise.all(
        lessonBlocksToCreate.map((lessonBlock) =>
          createLessonBlock({
            blockId,
            courseId,
            lessonId: lesson._id,
            values: lessonBlock,
          }),
        ),
      );
      await refetchCourseBlocks();
      form.reset();
    } catch (e: any) {
      console.log("e", e);
      setError(e?.message);
    }
  };

  return (
    <form
      onSubmit={form.onSubmit(handleSubmit)}
      className={classes.createCourseBlockForm}
    >
      <Flex className={classes.createCourseBlockFormContent}>
        <Input
          {...form.getInputProps("name")}
          placeholder="Введіть назву урока"
          className={classes.blockNameInput}
          size="sm"
          mr="sm"
        />

        <Button
          type="submit"
          size="sm"
          fz="sm"
          display="block"
          disabled={form.getValues().name.length <= 2}
          className={classes.createBlockButton}
        >
          Створити урок
        </Button>
      </Flex>
      {error && (
        <Text mt="md" className={classes.errorField}>
          {error}
        </Text>
      )}
    </form>
  );
};

export default CreateLessonForm;
