import { Box, Button, Flex, Modal, Select, Text, Title } from "@mantine/core";
import { hasLength, isEmail, useForm } from "@mantine/form";
import { useState } from "react";
import Input from "../../../components/Inputs/Input";
import classes from "./RootPage.module.css";
import { User, UserRole } from "../../../api/users";

type CreateUserModal = {
  opened: boolean;
  onClose: () => void;
  onSubmit: (values: CreateUserFormValues) => Promise<void>;
  defaultValues?: User | null;
};
export type CreateUserFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  telegramName: string;
  discordName: string;
  phoneNumber: string;
  role: UserRole;
};
const RootPage: React.FC<CreateUserModal> = ({
  opened,
  onClose,
  onSubmit,
  defaultValues,
}) => {
  const [error, setError] = useState<string>("");
  const isEditMode = !!defaultValues;
  const form = useForm<CreateUserFormValues>({
    mode: "controlled",
    initialValues: defaultValues
      ? {
          firstName: defaultValues.firstName,
          lastName: defaultValues.lastName,
          email: defaultValues.email,
          telegramName: defaultValues.telegramName,
          discordName: defaultValues.discordName,
          phoneNumber: defaultValues.phoneNumber,
          role: defaultValues.role,
        }
      : {
          firstName: "",
          lastName: "",
          email: "",
          telegramName: "",
          discordName: "",
          phoneNumber: "",
          role: UserRole.USER,
        },
    validate: {
      email: isEmail("Invalid email"),
    },
  });

  const handleSubmit = async (values: CreateUserFormValues) => {
    try {
      setError("");

      await onSubmit(values);
      onClose();
    } catch (e: any) {
      console.log("e", e);
      setError(e?.message);
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      bg="#000000"
      overlayProps={{ className: classes.modalOverlay }}
      title={isEditMode ? "Оновити дані юзера" : "Створити нового юзера"}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Box display="flex" w="100%">
          <Input
            required
            {...form.getInputProps("firstName")}
            label="Імʼя"
            placeholder="Введіть Імʼя"
            w="50%"
            pr="10px"
          />
          <Input
            required
            {...form.getInputProps("lastName")}
            label="Прізвище"
            placeholder="Введіть Прізвище"
            w="50%"
          />
        </Box>
        <Input
          required
          {...form.getInputProps("email")}
          mt="sm"
          label="E-mail"
          placeholder="Введіть E-mail"
        />
        <Input
          required
          {...form.getInputProps("phoneNumber")}
          mt="sm"
          label="Номер телефону"
          placeholder="Введіть ваш номер телефону"
        />
        <Input
          required
          {...form.getInputProps("telegramName")}
          mt="sm"
          label="Телеграм"
          placeholder="Введіть ваш нікнейм в Телеграм"
        />
        <Input
          required
          {...form.getInputProps("discordName")}
          mt="sm"
          label="Діскорд"
          placeholder="Введіть ваш нікнейм в Діскорд"
        />
        <Select
          required
          {...form.getInputProps("role")}
          mt="sm"
          label="Роль"
          placeholder="Виберіть роль"
          data={["ADMIN", "MENTOR", "USER"]}
          classNames={{
            dropdown: classes.selectDropdown,
          }}
        />
        {error && (
          <Text mt="md" className={classes.errorField}>
            {error}
          </Text>
        )}
        <Button
          type="submit"
          mt="xl"
          className={classes.submitButton}
          size="lg"
          fz="md"
          ml="auto"
          display="block"
        >
          {isEditMode ? "Оновити" : "Створити"}
        </Button>
      </form>
    </Modal>
  );
};

export default RootPage;
