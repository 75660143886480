import { Box, Button, Flex, Modal, Text, Title } from "@mantine/core";
import { useState } from "react";
import dayjs from "dayjs";
import classes from "../../StudyFlow.module.css";
import {
  createLessonGrade,
  getLessonGrade,
  HomeWork,
  HomeWorkStatus,
  LessonBlockType,
  updateHomework,
} from "../../../../../api/courses";
import Textarea from "../../../../../components/Inputs/Textarea";
import RichTextContent from "../../../../../components/RichTextContent";
import { ReactComponent as AttachmentIcon } from "../../../../../assets/icons/AttachmentIcon.svg";
import { bodyTextColorHex } from "../../../../../theme";
import { fetchFile } from "../../../../../api";
import NumberInput from "../../../../../components/Inputs/NumberInput";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

type CreateUserModal = {
  homeworks: HomeWork[];
  userId: string;
  opened: boolean;
  onClose: () => void;
  refetchHomeworks: () => Promise<void>;
};

const formatDate = (date: string) =>
  dayjs(new Date(date)).format("MM.DD.YYYY | h:mm");

const HomeworkConversationModal: React.FC<CreateUserModal> = ({
  opened,
  userId,
  homeworks,
  onClose,
  refetchHomeworks,
}) => {
  const homeWork = homeworks[0];
  const homeWorkLessonBlock = homeWork.courseLesson.lessonBlocks.find(
    (lessonBlock) => lessonBlock.type === LessonBlockType.HomeWork,
  );
  const { studyFlowId } = useParams();
  const { data: fetchedGrade } = useQuery({
    queryKey: ["grade", homeWorkLessonBlock?._id, userId],
    queryFn: async () =>
      homeWorkLessonBlock?._id && userId
        ? getLessonGrade(homeWorkLessonBlock?._id, userId)
        : undefined,
  });
  const [value, setValue] = useState<string>("");
  const [grade, setGrade] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const sortedHomeworks = homeworks.sort(
    (first, second) =>
      new Date(first.createdAt).getTime() -
      new Date(second.createdAt).getTime(),
  );

  const homeworkLessonBlock = homeWork.courseLesson.lessonBlocks.find(
    (lessonBlock) => lessonBlock.type === LessonBlockType.HomeWork,
  );

  const handleUpdateHomework = async (
    status: HomeWorkStatus.Aproved | HomeWorkStatus.Rejected,
  ) => {
    setError("");

    const lastSubmittedHomework = (sortedHomeworks as HomeWork[]).findLast(
      (hw: HomeWork) => hw.status === HomeWorkStatus.Submitted,
    );
    if (!lastSubmittedHomework) {
      setError("Не має домашніх завдання для відповіді");
      return;
    }
    try {
      setError("");
      setIsLoading(true);
      if (status === HomeWorkStatus.Aproved) {
        const lessonBlockId =
          lastSubmittedHomework.courseLesson.lessonBlocks.find(
            (lessonBlock) => lessonBlock.type === LessonBlockType.HomeWork,
          )?._id || "";
        await createLessonGrade(lessonBlockId, homeWork.user._id, grade);
      }
      await updateHomework(
        homeWork.courseLesson._id,
        lastSubmittedHomework?._id,
        status,
        value,
      );
      await refetchHomeworks();
      setValue("");
      setGrade(0);
      setIsLoading(false);
    } catch (e: any) {
      setError(e?.message);
      setIsLoading(false);
    }
  };

  const lastRejectedOrApproved = [
    HomeWorkStatus.Rejected,
    HomeWorkStatus.Aproved,
  ].includes(homeworks[homeworks.length - 1].status);
  const handleFileDownload = async (filePath: string, name: string) => {
    const blob = await fetchFile("/files-manager/download-file", filePath);
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = name;
    link.click();
  };
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      bg="#000000"
      overlayProps={{ className: classes.modalOverlay }}
      size="800px"
      title="Домашнє завдання"
    >
      <Flex
        className={classes.lessonContent}
        direction="column"
        justify="space-between"
      >
        <Flex direction="column" mb="32px">
          <Title fz="22px" fw="700">
            {homeWork.courseLesson.name}
          </Title>
          {homeworkLessonBlock?.content && (
            <RichTextContent content={homeworkLessonBlock?.content} />
          )}
        </Flex>

        <Title fz="18px" fw="600" mb="10px">
          Спілкування
        </Title>
        <Box px="10px">
          {sortedHomeworks.map((homeWork) => {
            const fileName = homeWork?.filePath?.split("/")[2];
            const filePath = homeWork?.filePath;
            if (homeWork.status === HomeWorkStatus.Submitted) {
              return (
                <Flex direction="column" mb="40px" key={homeWork._id}>
                  <Flex mb="5px">
                    <Box fw="500" mr="20px">
                      Студент {homeWork.user.firstName}
                    </Box>
                    <Box className={classes.commentDate}>
                      {formatDate(homeWork.createdAt)}
                    </Box>
                  </Flex>
                  <Box className={classes.comment}>{homeWork.userComment}</Box>
                  {filePath && fileName && (
                    <File
                      name={fileName}
                      onClick={async () =>
                        handleFileDownload(filePath, fileName)
                      }
                    />
                  )}
                </Flex>
              );
            }
            return (
              <>
                <Flex direction="column" mb="40px">
                  <Flex mb="5px">
                    <Box fw="500" mr="20px">
                      Студент {homeWork.user.firstName}
                    </Box>
                    <Box className={classes.commentDate}>
                      {formatDate(homeWork.createdAt)}
                    </Box>
                  </Flex>
                  <Box className={classes.comment}>{homeWork.userComment}</Box>
                  {filePath && fileName && (
                    <File
                      name={fileName}
                      onClick={async () =>
                        handleFileDownload(filePath, fileName)
                      }
                    />
                  )}
                </Flex>
                <Flex direction="column" mb="40px">
                  <Flex mb="5px">
                    <Box className={classes.commentAuthor} mr="20px">
                      Ментор {homeWork.mentor?.firstName}
                    </Box>
                    <Box className={classes.commentDate}>
                      {formatDate(homeWork.createdAt)}
                    </Box>
                  </Flex>

                  <Box className={classes.comment}>
                    {homeWork.mentorComment}
                  </Box>
                </Flex>
              </>
            );
          })}
        </Box>
        <Textarea
          mt="lg"
          w="100%"
          label="Комментар"
          variant="black"
          size="md"
          onChange={(e) => setValue(e?.target?.value ?? "")}
          disabled={lastRejectedOrApproved}
          value={value}
          placeholder="Введіть коментар"
        />
        {fetchedGrade?.grade ? (
          <Text mt="md" fz="lg">
            Оцінка: <b>{fetchedGrade?.grade}</b>
          </Text>
        ) : (
          <NumberInput
            variant="black"
            mt="sm"
            size="md"
            label="Оцінка"
            allowNegative={false}
            allowDecimal={false}
            placeholder="Введіть оцінку"
            disabled={lastRejectedOrApproved}
            value={grade}
            onChange={(value) =>
              setGrade(typeof value === "string" ? parseInt(value, 10) : value)
            }
          />
        )}
        {error && (
          <Text mt="md" className={classes.errorField}>
            {error}
          </Text>
        )}
        <Flex justify="flex-end" w="100%" mt="md">
          <Button
            size="sm"
            fz="md"
            display="block"
            mr="sm"
            onClick={async () => handleUpdateHomework(HomeWorkStatus.Rejected)}
            disabled={!value.length || lastRejectedOrApproved}
            loading={isLoading}
          >
            Відхилити
          </Button>
          <Button
            size="sm"
            fz="md"
            display="block"
            color="green"
            onClick={async () => handleUpdateHomework(HomeWorkStatus.Aproved)}
            disabled={!value.length || lastRejectedOrApproved || grade === 0}
            loading={isLoading}
          >
            Затвердити
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

const File = ({
  name,
  onClick,
}: {
  name: string;
  onClick?: () => Promise<void>;
}) => (
  <Flex
    mt="sm"
    display="flex"
    onClick={onClick}
    className={onClick && classes.file}
  >
    <AttachmentIcon />
    <Text ml="sm" c={bodyTextColorHex}>
      {name}
    </Text>
  </Flex>
);

export default HomeworkConversationModal;
