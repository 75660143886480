import { FC, useState } from "react";
import { ActionIcon, Flex, Text, Title } from "@mantine/core";
import classes from "../Course.module.css";
import { generatePath, useNavigate } from "react-router-dom";
import { COURSE_LESSON_PATH } from "../../../routes";
import {
  IconArrowRight,
  IconDeviceFloppy,
  IconEdit,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import {
  Lesson,
  removeCourseLesson,
  updateCourseLesson,
} from "../../../../api/courses";
import { modals } from "@mantine/modals";
import Input from "../../../../components/Inputs/Input";

type Props = {
  lesson: Lesson;
  courseId: string;
  blockId: string;
  refetchCourseBlocks: () => Promise<any>;
};

const LessonComponent: FC<Props> = ({
  lesson,
  courseId,
  blockId,
  refetchCourseBlocks,
}) => {
  const navigate = useNavigate();
  const [editableName, setEditableName] = useState<string | null>(null);

  const removeLessonModal = (lesson: Lesson) =>
    modals.openConfirmModal({
      title: "Підтвердіть дію",
      size: "sm",
      radius: "md",
      withCloseButton: false,
      children: (
        <Text size="sm">
          Чи впевнені що хочете видалити урок <b>{lesson.name}</b>?
        </Text>
      ),
      labels: { confirm: "Підтвердити", cancel: "Відмінити" },
      onCancel: () => {},
      onConfirm: async () => {
        await removeCourseLesson(courseId, blockId, lesson._id);
        await refetchCourseBlocks();
      },
    });

  return (
    <div className={classes.lessons}>
      <Flex className={classes.lesson} mb="12px">
        {editableName !== null ? (
          <Flex align="center">
            <Input
              value={editableName}
              onChange={(e) => {
                const value = e.target.value;

                setEditableName(value);
              }}
              required
              size="sm"
              className={classes.editInput}
              placeholder="Введіть назву блока курсу"
            />
            <ActionIcon
              variant="filled"
              aria-label="Remove"
              disabled={editableName.length <= 2}
              bg="green"
              onClick={async () => {
                await updateCourseLesson(
                  courseId,
                  blockId,
                  lesson._id,
                  editableName,
                );
                setEditableName(null);
                refetchCourseBlocks();
              }}
            >
              <IconDeviceFloppy
                style={{ width: "70%", height: "70%" }}
                stroke={1.5}
              />
            </ActionIcon>
            <ActionIcon
              ml="8px"
              variant="filled"
              aria-label="Remove"
              onClick={() => setEditableName(null)}
            >
              <IconX style={{ width: "70%", height: "70%" }} stroke={1.5} />
            </ActionIcon>
          </Flex>
        ) : (
          <Flex align="center">
            <Title fz="14px" fw="500" mr="16px">
              {lesson.name}
            </Title>
            <ActionIcon
              variant="filled"
              aria-label="Remove"
              mr="15px"
              onClick={() => setEditableName(lesson.name)}
            >
              <IconEdit style={{ width: "70%", height: "70%" }} stroke={1.5} />
            </ActionIcon>
          </Flex>
        )}

        <Flex align="center">
          <ActionIcon
            variant="filled"
            aria-label="Remove"
            mr="10px"
            onClick={() => {
              removeLessonModal(lesson);
            }}
          >
            <IconTrash style={{ width: "70%", height: "70%" }} stroke={1.5} />
          </ActionIcon>
          <ActionIcon
            variant="filled"
            aria-label="Go-to"
            onClick={() =>
              navigate(
                generatePath(COURSE_LESSON_PATH, {
                  courseId,
                  blockId,
                  lessonId: lesson._id,
                }),
              )
            }
          >
            <IconArrowRight
              style={{ width: "70%", height: "70%" }}
              stroke={1.5}
            />
          </ActionIcon>
        </Flex>
      </Flex>
    </div>
  );
};

export default LessonComponent;
