import { FC, useState } from "react";
import { ActionIcon, Button, Flex, Text, Title } from "@mantine/core";
import classes from "../Course.module.css";
import { ReactComponent as CourseBlockIcon } from "../../../../assets/icons/CourseBlock.svg";
import {
  IconDeviceFloppy,
  IconEdit,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import {
  CourseBlock as CourseBlockType,
  removeCourseBlock,
  updateCourseBlock,
} from "../../../../api/courses";
import { modals } from "@mantine/modals";
import CreateLessonForm from "./CreateLessonForm";
import { bodyTextColorHex } from "../../../../theme";
import LessonComponent from "./LessonComponent";
import Input from "../../../../components/Inputs/Input";

type Props = {
  courseBlock: CourseBlockType;
  courseId: string;
  refetchCourseBlocks: () => Promise<any>;
};

const CourseBlock: FC<Props> = ({
  courseBlock,
  courseId,
  refetchCourseBlocks,
}) => {
  const [editableName, setEditableName] = useState<string | null>(null);
  const openRemoveModal = (courseBlock: CourseBlockType) =>
    modals.openConfirmModal({
      title: "Підтвердіть дію",
      size: "sm",
      radius: "md",
      withCloseButton: false,
      children: (
        <Text size="sm">
          Чи впевнені що хочете видалити блок курса <b>{courseBlock.name}</b> та
          всі уроки в ньому?
        </Text>
      ),
      labels: { confirm: "Підтвердити", cancel: "Відмінити" },
      onCancel: () => {},
      onConfirm: async () => {
        await removeCourseBlock(courseId, courseBlock._id);
        await refetchCourseBlocks();
      },
    });

  return (
    <Flex className={classes.pageContent} pb="29px">
      <Flex align="center" mb="20px" justify="space-between">
        <Flex align="center">
          <CourseBlockIcon />

          {editableName !== null ? (
            <Flex ml="lg" align="center">
              <Input
                value={editableName}
                onChange={(e) => {
                  const value = e.target.value;

                  setEditableName(value);
                }}
                mr="sm"
                required
                size="sm"
                className={classes.editInput}
                placeholder="Введіть назву блока курсу"
              />
              <ActionIcon
                variant="filled"
                aria-label="Remove"
                disabled={editableName.length <= 2}
                bg="green"
                onClick={async () => {
                  await updateCourseBlock(
                    courseId,
                    courseBlock._id,
                    editableName,
                  );
                  setEditableName(null);
                  refetchCourseBlocks();
                }}
              >
                <IconDeviceFloppy
                  style={{ width: "70%", height: "70%" }}
                  stroke={1.5}
                />
              </ActionIcon>
              <ActionIcon
                ml="8px"
                variant="filled"
                aria-label="Remove"
                onClick={() => setEditableName(null)}
              >
                <IconX style={{ width: "70%", height: "70%" }} stroke={1.5} />
              </ActionIcon>
            </Flex>
          ) : (
            <>
              <Text fz={24} ml={20} mr="16px">
                {courseBlock.name}
              </Text>
              <ActionIcon
                variant="filled"
                aria-label="Remove"
                mr="15px"
                onClick={() => setEditableName(courseBlock.name)}
              >
                <IconEdit
                  style={{ width: "70%", height: "70%" }}
                  stroke={1.5}
                />
              </ActionIcon>
            </>
          )}
        </Flex>
        <ActionIcon
          variant="filled"
          aria-label="Remove"
          mr="15px"
          onClick={() => {
            openRemoveModal(courseBlock);
          }}
        >
          <IconTrash style={{ width: "70%", height: "70%" }} stroke={1.5} />
        </ActionIcon>
      </Flex>
      <Flex justify="flex-end" mb="29px">
        <CreateLessonForm
          courseId={courseId}
          blockId={courseBlock._id}
          refetchCourseBlocks={refetchCourseBlocks}
        />
      </Flex>
      {courseBlock.courseLessons.length ? (
        <div className={classes.lessons}>
          {courseBlock.courseLessons.map((lesson) => (
            <LessonComponent
              key={lesson._id}
              lesson={lesson}
              courseId={courseId}
              blockId={courseBlock._id}
              refetchCourseBlocks={refetchCourseBlocks}
            />
          ))}
        </div>
      ) : (
        <Title order={4} fz={16} fw="normal" c={bodyTextColorHex} ta="center">
          Цей блок немає уроків
        </Title>
      )}
    </Flex>
  );
};

export default CourseBlock;
