export const ROOT_PATH = "/";

export const SIGN_UP_PATH = "/sign-up";
export const SING_IN_PATH = "/sign-in";
export const FORGOT_PASSWORD_PATH = "/forgot-password";
export const RESET_PASSWORD_PATH = "/reset-password";

export const STUDY_FLOWS_PATH = "/study-flows";
export const STUDY_FLOW_PATH = "/study-flows/:studyFlowId";

export const COURSES_PATH = "/courses";
export const COURSE_PATH = "/courses/:courseId";
export const RESOURCES_PATH = "/courses/:courseId/resources";
export const COURSE_LESSON_PATH =
  "/courses/:courseId/blocks/:blockId/lessons/:lessonId";
