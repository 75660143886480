import { FC, useState } from "react";
import { hasLength, useForm } from "@mantine/form";
import { Button, Flex, Text } from "@mantine/core";
import classes from "../Course.module.css";
import Input from "../../../../components/Inputs/Input";
import { createCourseResource } from "../../../../api/courses";
import RichText from "../../../../components/RichText";

type FormValues = { name: string };

const emptyHtml = "<p></p>";
const CreateCourseResourceForm: FC<{
  courseId: string;
  refetchResources: () => Promise<any>;
}> = ({ courseId, refetchResources }) => {
  const [html, setHTML] = useState<string>(emptyHtml);
  const [error, setError] = useState<string>("");

  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
  const form = useForm<FormValues>({
    mode: "controlled",
    initialValues: { name: "" },
    validate: {
      name: hasLength({ min: 2 }, "Must be at least 2 characters"),
    },
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      setError("");

      await createCourseResource(courseId, values.name, html);
      await refetchResources();
      setHTML(emptyHtml);

      form.reset();
      setShowCreateForm(false);
    } catch (e: any) {
      console.log("e", e);
      setError(e?.message);
    }
  };
  const isEmptyForm = form.getValues().name.length <= 2 || html === emptyHtml;
  return (
    <form onSubmit={form.onSubmit(handleSubmit)} style={{ width: "100%" }}>
      <Flex direction="column">
        <Flex justify="flex-end">
          <Button
            size="sm"
            fz="sm"
            display="block"
            fullWidth={false}
            disabled={!isEmptyForm}
            mb="20px"
            onClick={() => setShowCreateForm((prev) => !prev)}
          >
            {showCreateForm ? "Сховати" : "Показати"} форму додавання
          </Button>
        </Flex>
        {showCreateForm && (
          <Flex direction="column">
            <Input
              {...form.getInputProps("name")}
              placeholder="Введіть назву ресурсу"
              mr="sm"
              mb="md"
            />
            <RichText content={html} onUpdate={setHTML} />
            {error && (
              <Text mt="md" c="red" className={classes.errorField}>
                {error}
              </Text>
            )}
            <Flex justify="flex-end">
              <Button
                mt="md"
                type="submit"
                size="md"
                fz="md"
                display="block"
                disabled={isEmptyForm}
              >
                Створити ресурс
              </Button>
            </Flex>
          </Flex>
        )}
      </Flex>
    </form>
  );
};

export default CreateCourseResourceForm;
