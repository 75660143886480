import cx from "clsx";
import { useCallback, useState } from "react";
import { Table, Checkbox, ScrollArea, Group, Text, keys } from "@mantine/core";
import classes from "./TableSelection.module.css";
import { User } from "../../../../../api/users";
import Input from "../../../../../components/Inputs/Input";

type UsersProps = {
  users: User[];
  selectedUsers: User[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<User[]>>;
};
const UsersTable: React.FC<UsersProps> = ({
  users,
  selectedUsers,
  setSelectedUsers,
}) => {
  const [search, setSearch] = useState("");

  const toggleRow = (id: string) =>
    setSelectedUsers((current) => {
      const userExisted = current.find((user) => user._id === id);
      if (userExisted) {
        return current.filter((item) => item._id !== id);
      }
      const newUser = users.find((user) => user._id === id) as User;
      return [...current, newUser];
    });
  const toggleAll = () =>
    setSelectedUsers((current) =>
      current.length === users.length ? [] : users,
    );

  const filterData = useCallback(
    (data: User[]): User[] => {
      const query = search.toLowerCase().trim();
      const searchKeys = [
        "lastName",
        "firstName",
        "email",
        "role",
      ] as (keyof User)[];
      return data
        .filter((item) =>
          searchKeys.some((key) => item[key].toLowerCase().includes(query)),
        )
        .sort((a, b) => a.lastName.localeCompare(b.lastName));
    },
    [users, search],
  );

  const rows = filterData(users).map((item) => {
    const selected = !!selectedUsers.find((user) => user._id === item._id);
    return (
      <Table.Tr
        key={item._id}
        className={cx({ [classes.rowSelected]: selected })}
      >
        <Table.Td style={{ width: 10 }}>
          <Checkbox checked={selected} onChange={() => toggleRow(item._id)} />
        </Table.Td>
        <Table.Td w="100px">
          <Group gap="sm">
            <Text size="sm" fw={500}>
              {item.firstName} {item.lastName}
            </Text>
          </Group>
        </Table.Td>
        <Table.Td w="100px">{item.email}</Table.Td>
        <Table.Td w="100px">{item.role}</Table.Td>
      </Table.Tr>
    );
  });

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
  };
  return (
    <>
      <Input
        placeholder="Пошук по будь-якому полю"
        mb="xs"
        size="sm"
        value={search}
        onChange={handleSearchChange}
        classNames={{ input: classes.searchInput }}
      />
      <ScrollArea>
        <Table verticalSpacing="sm">
          <Table.Thead>
            <Table.Tr>
              <Table.Th style={{ width: 10 }}>
                <Checkbox
                  onChange={toggleAll}
                  checked={selectedUsers.length === users.length}
                  indeterminate={
                    selectedUsers.length > 0 &&
                    selectedUsers.length !== users.length
                  }
                />
              </Table.Th>
              <Table.Th w="100px">Студент</Table.Th>
              <Table.Th w="100px">E-мейл</Table.Th>
              <Table.Th w="100px">Роль</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {!!rows?.length ? (
              rows
            ) : (
              <Table.Tr>
                <Table.Td style={{ width: 10 }}>
                  <Checkbox disabled />
                </Table.Td>
                <Table.Th w="100px">Не знайдено студентів</Table.Th>
                <Table.Th w="100px"></Table.Th>
                <Table.Th w="100px"></Table.Th>
              </Table.Tr>
            )}
          </Table.Tbody>
        </Table>
      </ScrollArea>
    </>
  );
};

export default UsersTable;
