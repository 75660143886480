import React from "react";
import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import SignIn from "./pages/Anonymous/SignIn";
import { SING_IN_PATH, COURSES_PATH, STUDY_FLOWS_PATH } from "./pages/routes";
import PrivateLayout from "./components/Layouts/PrivateLayout";
import Users from "./pages/Private/Users";
import Courses from "./pages/Private/Courses";
import Course from "./pages/Private/Course";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ModalsProvider } from "@mantine/modals";
import classes from "./App.module.css";
import StudyFlows from "./pages/Private/StudyFlows";
import StudyFlow from "./pages/Private/StudyFlow";
import Lesson from "./pages/Private/Lesson";
import Resources from "./pages/Private/Resources";

const router = createBrowserRouter([
  {
    path: "/",
    element: <PrivateLayout />,
    children: [
      {
        index: true,
        element: <Users />,
      },
      {
        path: COURSES_PATH,
        children: [
          {
            index: true,
            element: <Courses />,
          },
          {
            path: ":courseId",
            children: [
              {
                index: true,
                element: <Course />,
              },
              {
                path: "blocks/:blockId/lessons/:lessonId",
                element: <Lesson />,
              },
              {
                path: "resources",
                element: <Resources />,
              },
            ],
          },
        ],
      },
      {
        path: STUDY_FLOWS_PATH,
        children: [
          {
            index: true,
            element: <StudyFlows />,
          },
          {
            path: ":studyFlowId",
            element: <StudyFlow />,
          },
        ],
      },
    ],
  },
  {
    path: SING_IN_PATH,
    element: <SignIn />,
  },
]);

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ModalsProvider
        modalProps={{ classNames: { overlay: classes.modalOverlay } }}
      >
        <RouterProvider router={router} />
      </ModalsProvider>
    </QueryClientProvider>
  );
}

export default App;
