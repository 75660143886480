import { useState } from "react";
import { Group, Box, Collapse, Text, UnstyledButton, rem } from "@mantine/core";
import clsx from "clsx";
import { ReactComponent as ArrowUp } from "../../assets/icons/ArrowUp.svg";
import classes from "./NavbarLinksGroup.module.css";
import { Link, matchPath, useLocation } from "react-router-dom";
import { ROOT_PATH } from "../../pages/routes";
import { NavItemLink } from "../Navbar/types";

interface LinksGroupProps {
  icon?: React.ReactNode;
  label: string;
  to: string;
  initiallyOpened?: boolean;
  links?: NavItemLink[];
  active?: boolean;
}

export function LinksGroup({
  icon,
  to,
  label,
  initiallyOpened,
  links,
  active,
}: LinksGroupProps) {
  const location = useLocation();
  const hasLinks = Array.isArray(links);
  const [opened, setOpened] = useState(initiallyOpened || false);
  const items = (hasLinks ? links : [])
    .filter((link) => !link?.hidden)
    .map((link) => (
      <Text<"a">
        component="a"
        className={classes.link}
        href={link.link}
        key={link.label}
        onClick={(event) => event.preventDefault()}
      >
        {link.label}
      </Text>
    ));

  const isActive =
    matchPath(to, location.pathname) ||
    links?.some((link) => matchPath(link.link, location.pathname));

  return (
    <>
      <UnstyledButton
        onClick={() => setOpened((o) => !o)}
        className={classes.control}
      >
        <Group justify="space-between" gap={0}>
          <Box className={classes.linkContainer}>
            <Link
              className={clsx(
                classes.navLink,
                isActive && classes.navLinkActive,
              )}
              to={to}
            >
              {icon}
              {label}
            </Link>
          </Box>
          {hasLinks && (
            <ArrowUp
              style={{
                width: rem(16),
                height: rem(16),
                transform: opened ? "rotate(-90deg)" : "none",
              }}
            />
          )}
        </Group>
      </UnstyledButton>
      {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
    </>
  );
}
