import React, { FC } from "react";
import clsx from "clsx";
import { TextInput, TextInputProps } from "@mantine/core";
import classes from "./Input.module.css";

const Input: FC<TextInputProps> = ({ classNames, ...props }) => {
  return (
    <TextInput
      {...props}
      classNames={{
        ...classNames,
        input: clsx(classes.input, (classNames as any)?.input),
        label: classes.label,
      }}
      size={props?.size || "lg"}
    />
  );
};

export default Input;
